<template>
  <div v-if="user">
    <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
    <base-loader :isLoading="isLoading"></base-loader>
    <v-container>
      <v-form @submit.prevent="save">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col>
                <h3>Personal Details</h3>
                <v-text-field
                  label="Name"
                  v-model="userModel.firstName"
                ></v-text-field>
                <v-text-field
                  label="Surname"
                  v-model="userModel.lastName"
                ></v-text-field>
                <v-text-field
                  label="Mobile"
                  v-model="userModel.telNumber"
                ></v-text-field>
                <v-text-field
                  label="Email"
                  v-model="userModel.email"
                ></v-text-field>
                <v-select
                  v-model="userModel.role"
                  v-if="__type && __type.roles"
                  item-text="name"
                  label="Role"
                  :items="__type.roles"
                ></v-select>
              </v-col>
              <!-- <v-col>
                <h3>Physical Address</h3>
                <v-text-field
                  label="Address 1"
                  v-model="userModel.addy1"
                ></v-text-field>
                <v-text-field
                  label="Address 2"
                  v-model="userModel.addy2"
                ></v-text-field>
                <v-text-field
                  label="Address 2"
                  v-model="userModel.addy2"
                ></v-text-field>
              </v-col> -->
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="success" type="submit">save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { User } from "@/models/User";
import { gql } from "apollo-boost";

export default {
  data() {
    return {
      isEdit: false,
      userModel: null,
      roles: {},
      isLoading: false,
      crumbs: [
        {
          text: "Users",
          disabled: false,
          href: "/admin/users",
        },
        {
          text: "User Edit",
          disabled: true,
        },
      ],
    };
  },
  apollo: {
    user: {
      query: gql`
        query User($id: ID!) {
          user(id: $id) {
            id
            firstName
            lastName
            fullAddress
            telNumber
            email
            role
            location
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id };
      },
      result(response) {
        this.userModel = new User();
        this.userModel.id = this.user.id;
        this.userModel.role = this.user.role;
        this.userModel.firstName = this.user.firstName;
        this.userModel.lastName = this.user.lastName;
        this.userModel.telNumber = this.user.telNumber;
        this.userModel.email = this.user.email;
      },
    },
    __type: {
      query: gql`
        query {
          __type(name: "Role") {
            roles: enumValues {
              name
            }
          }
        }
      `,
    },
  },
  methods: {
    async save() {
      this.isLoading = true;
      const self = this;

      await this.$apollo
        .mutate({
          mutation: gql`
            mutation updateUser($user: UserUpdateInput!) {
              updateUser(user: $user) {
                id
                updated
              }
            }
          `,
          variables: {
            user: {
              id: self.userModel.id,
              role: self.userModel.role,
              firstName: self.userModel.firstName,
              lastName: self.userModel.lastName,
              telNumber: self.userModel.telNumber,
              email: self.userModel.email,
            },
          },
        })
        .then((response) => {
          if (response) {
            self.isLoading = false;
            self.$swal({
              title: "Success",
              text: "User Details updated successfully",
              icon: "success",
              confirmButtonColor: "#c79477",
            });
          }
        })
        .catch((e) => {
          console.log(`User Detail Save Failed: ${JSON.stringify(e)}`);
          self.errorMsg = `${e.message}`;
          self.$swal({
            title: "Error",
            text: "Save Failed, Please try again later!",
            icon: "error",
            confirmButtonColor: "#c79477",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
